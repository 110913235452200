






































import { Inject } from '@cds/common';
import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import {VueCropper} from 'vue-cropper'
import { WechatImageTextService } from '@/services/wechat-imagetext-service';
@Component({
  components: {
    VueCropper
  }
})
export default class index extends Vue {
  @Prop({ default: true }) public title!: String;
  @Prop({ default: true }) public imgUrl!: String;
  @Prop({ default: true }) private dialogStatusVisible!: Boolean; 

  public option:any= {
        img: '', // 裁剪图片的地址
        info: true, // 裁剪框的大小信息
        outputSize: 1, // 裁剪生成图片的质量
        outputType: 'png', // 裁剪生成图片的格式
        canScale: false, // 图片是否允许滚轮缩放
        autoCrop: true, // 是否默认生成截图框
        autoCropWidth: 212, // 默认生成截图框宽度
        autoCropHeight: 106, // 默认生成截图框高度
        fixedBox: false, // 固定截图框大小 不允许改变
        fixed: false, // 是否开启截图框宽高固定比例
        fixedNumber: [2, 1], // 截图框的宽高比例
        full: false, // 是否输出原图比例的截图
        canMoveBox: true, // 截图框能否拖动
        original: true, // 上传图片按照原始比例渲染
        centerBox: false, // 截图框是否被限制在图片里面
        infoTrue: true, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
        canMove: true,
  }
  
  public loading:boolean = true;
  @Inject(WechatImageTextService) private wechatImageTextService!: WechatImageTextService;

  public async mounted(): Promise<void> {
     this.getBase64(this.imgUrl)
  }
  
  public async sureStatus(){
       // 获取截图的 blob 数据
       const cropper = ( this.$refs.cropper as any)
       cropper.getCropBlob((blob:any) => {
          console.log(blob)
          // this.dialogStatusVisible = false
        })
        // 获取截图的 base64 数据
        cropper.getCropData((data :any) => {
          console.log("获取 base64 数据:", data)
          console.log(this.base64ToFile(data,'1.png'))
          //这里写上传给后端的逻辑
          this.uploadImage(data)         
        })
    }
    @Emit('vueCropper')
    public async uploadImage(data:any){
         let that = this
          let form = new FormData()
          form.append('files', that.base64ToFile(data,'1.png'))
          const res = await  this.wechatImageTextService.uploadImages(form);        
            if (res) {
              this.$notify.success({
                title: '成功',
               message: '裁剪图片上传成功！'
            });  
            return res.url;
          }       
    }

    //解码base64，以file上传给后端
  public base64ToFile(urlData:any, fileName:any) {
      let arr = urlData.split(',');
      let mime = arr[0].match(/:(.*?);/)[1];
      let bytes = atob(arr[1]); // 解码base64
      let n = bytes.length
      let ia = new Uint8Array(n);
      while (n--) {
          ia[n] = bytes.charCodeAt(n);
      }
      console.log(mime,ia)
      return new File([ia], fileName, { type: mime });
    }


  //网络图片转换为base64 不然会提示跨域
  public async getBase64(imgUrl:any) {
    if (!imgUrl) {
      this.$notify.error({
          title: '失败',
          message: '裁剪图片上传成功！'
      });  
      return;
    }
    let params ={
      img:imgUrl
    }
    const res = await  this.wechatImageTextService.encodeImg(params); 
    this.option.img = res; 
    this.loading = false;      
  }

  //返回
  @Emit('vueCropper')
  public cancel(): Boolean {
    return false
  }
  
}
