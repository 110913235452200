


























































































































































































import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { Inject } from '@cds/common';
import { I18nService } from '@cds/i18n';
import moment from 'moment';
//引入组件
import VueUeditorWrap from "vue-ueditor-wrap"; //es6
import { WechatImageTextService } from '@/services/wechat-imagetext-service';
import { WechatSendMessageGroupService } from '@/services/wechat-send-messages-group-service';

import wechatImageText from '@/components/wechatImageText/index.vue';
import vueCropper from '@/components/vueCropper/vueCropper.vue';
import openDialog from '@/components/wechatEditor/openDialog.vue';


@Component({
  components: {
    VueUeditorWrap,
    wechatImageText,
    openDialog,
    vueCropper
  }
})
export default class addImageText extends Vue {
  public myConfig = {
    // 编辑器不自动被内容撑高
    autoHeightEnabled: false,
    // 初始容器高度
    initialFrameHeight: 500,
    // 初始容器宽度
    initialFrameWidth: "100%",
    // 上传文件接口（这个地址是我为了方便各位体验文件上传功能搭建的临时接口，请勿在生产环境使用！！！）
    serverUrl: '/api/application/ueditor/config',
    catchRemoteImageEnable:true,
    // UEditor 资源文件的存放路径，如果你使用的是 vue-cli 生成的项目，通常不需要设置该选项，vue-ueditor-wrap 会自动处理常见的情况，如果需要特殊配置，参考下方的常见问题2
    UEDITOR_HOME_URL: "/ueditor/",
    toolbars: [[
      'fullscreen', 'source', '|', 'undo', 'redo', 'bold', 'italic', 'underline', 'fontborder', 'strikethrough', 'superscript', 'subscript',
      'removeformat', 'formatmatch', 'autotypeset', 'blockquote', 'pasteplain', 'forecolor',
      'backcolor', 'insertorderedlist', 'insertunorderedlist', 'selectall', 'cleardoc',
      'fontfamily', 'fontsize', 'simpleupload', 'insertimage', 'emotion', /*'scrawl',*/
            /*'music',*/ 'insertvideo', 'insertaudio', 'attachment', 'map',
      'rowspacingtop', 'rowspacingbottom', 'lineheight', 'link', 'unlink'
    ]],
    'fontfamily': [
      { label: '', name: 'songti', val: '宋体,SimSun' },
      { label: '仿宋', name: 'fangsong', val: '仿宋,FangSong' },
      { label: '仿宋_GB2312', name: 'fangsong', val: '仿宋_GB2312,FangSong' },
      { label: '', name: 'kaiti', val: '楷体,楷体_GB2312, SimKai' },
      { label: '', name: 'yahei', val: '微软雅黑,Microsoft YaHei' },
      { label: '', name: 'heiti', val: '黑体, SimHei' },
      { label: '', name: 'lishu', val: '隶书, SimLi' },
      { label: '', name: 'andaleMono', val: 'andale mono' },
      { label: '', name: 'arial', val: 'arial, helvetica,sans-serif' },
      { label: '', name: 'arialBlack', val: 'arial black,avant garde' },
      { label: '', name: 'comicSansMs', val: 'comic sans ms' },
      { label: '', name: 'impact', val: 'impact,chicago' },
      { label: '', name: 'timesNewRoman', val: 'times new roman' }
    ]
  };
  public dialogStatusVisible:boolean = false;//裁剪弹框
  public imgUrl:string = '';//裁剪图片地址
  public cropperType:string = '';//裁剪图片类型 是封面还是。。
  public ishowImage: Boolean = false;//默认是没有选中数据tableData==0
  public title: String = "选择图文素材";
  public labelPosition: String = 'top';
  public form: any = {
    agentId: '',
    sn: 0,
    sendType: 0,
    tosend: 0,
    moments:0,//是否分享到朋友圈
    identify:0,//是否开启图片保护
    toall: 1,
    picnews: 0,
    title: null,
    picurl: null,
    spicurl: null,
    htmlContent: '',
    history: 0,
    historyUrl:'',//图文历史链接
    groupIds: ''
  };
  public spicurlAlt: String = '';
  public createTime: any = '';
  public disabledTosend: Boolean = true;//是否可以转发 默认不可编辑
  public disabledToall: Boolean = false; //可见范围 默认可编辑
  public queryParams: any = { //查询参数
    agentId: '',
    total: 0
  };
  public querySend: any = {
    channelId: ''
  }
  public rules: any = {
    agentId: [
      { required: true, message: '请选择专栏', trigger: ['blur', 'change'] }],
    title: [
      { required: true, message: '请输入标题', trigger: 'blur' }
    ],
    picurl: [
      { required: true, message: '请上传封面图片', trigger: ['blur', 'change'] }
    ]
  }
  public activeName: String = 'first';
  public seen: Boolean = false;
  public chooseFlag: any = '';//鼠标移上去的索引
  public type: any = ''; //判断是修改还是添加
  public id: any = '';
  public imageType: String = ''; //判断是封面还是图文列表/历史消息 封面
  public dialogFormVisible: boolean = false;//是否显示弹框
  public readonly: boolean = true;
  public titles: String = "选择图文素材";
  public agentDisable: Boolean = false;//专栏、应用是否可选
  public loading: boolean = false;
  public isAdd: boolean = false;

  public articleIds: any[] = []; //存储之前的id
  public articleType: any[] = []; //存储类型
  private applications: any[] = []; //存储专栏
  private imageList: any[] = []; //存储图文集合
  private sendMessageList: any[] = []; //存储群发消息分组


  @Inject(WechatImageTextService) private wechatImageTextService!: WechatImageTextService;
  @Inject(WechatSendMessageGroupService) private wechatSendMessageGroupService!: WechatSendMessageGroupService;

  @Watch('form.htmlContent', { deep: true })
  handleWatch() {
  //  this.form.htmlContent = this.$xss(this.form.htmlContent);
  }

  public async created(): Promise<void> {
    const currentTime = new Date().getTime();
    this.createTime = moment(currentTime).format('YYYY-MM-DD HH:mm')
    this.type = JSON.parse(localStorage.getItem('imageTextType') || '')

    console.log(this.type)
    //查询专栏
    await this.applicationsList()
    console.log('this.type', this.type)
    if (this.type != '1') { //代表是修改
      this.id = this.type //拿到主图文id
      await this.articleGet();
      this.agentDisable = true;
      this.form.historyUrl = `${location.origin}/historynew-wecom-front/?articleId=`+this.form.id
      // if(this.type.publish == 1){
      //   this.agentDisable = true;
      // }
    } else { //添加的话默认push数据
      this.articleIds.push('0');
      this.imageList.push(this.form)
    }

  }
  //添加图文
  public addImage() {
    if (this.imageList.length >= 8) {
      this.$message({
        type: 'info',
        message: '最多只能添加8条图文'
      });
      return;
    }
    const addRef = (this.$refs.form as any)
    addRef.validate(async (valid: any) => {
      if (valid) {
        //数据存储后清空表单
        this.resetForm()
        this.imageList.push(this.form)
        this.agentDisable = true;
        this.form.agentId = this.imageList[0].agentId
        this.agentChanged(this.form.agentId)
      } else {
        console.log('error submit!!');
        return false;
      }
    });
  }

  //多图文添加的时候需要重置表单
  public resetForm() {
    this.form = {
      sn: 0,
      sendType: 0,
      tosend: 0,
      moments:0,//是否分享到朋友圈
      toall: 1,
      picnews: 0,
      history: 0,
      title: null,
      picurl: null,
      spicurl: null,
      htmlContent: '',
      groupIds: ''
    };
    this.disabledTosend = true;//是否可以转发 默认不可编辑
    this.disabledToall = false; //可见范围 默认可编辑
  }

  //多图文点击到具体图文修改
  public updateImage(row: any) {
    this.form = row;
    this.form.agentId = this.form.agentId.toString()
    this.form.historyUrl = `${location.origin}/historynew-wecom-front/?articleId=`+this.form.id
    this.agentChanged(this.form.agentId)
    this.changeSendType(this.form.sendType)
  }
  //多图文点击到具体图文前端删除
  public async deleteImage(index: any) {
    try {
      await this.$confirm('确定要删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      });
      this.imageList.splice(index, 1);
      this.$message({
        message: '删除成功',
        type: 'success'
      });
    } catch (e) {
      this.$message({
        type: 'info',
        message: '已取消删除'
      });
    }

  }

  //选择图文
  public chooseImage(){
    localStorage.setItem('acceptChannelId',this.form.channelId)
    let child =  (this.$refs.childImage as wechatImageText)
    child.checkCont()
    child.types = '1'
  }

  //得到图文组件传过来的值
  public async getChildImage(val: any) {
    this.id = val.id
    await this.articleGet();
    this.ishowImage = true
  }

  //鼠标移入移出
  public enter(e: any, index: any, list: any) {
    this.chooseFlag = index
    this.seen = true;
  }
  public leave(e: any, index: any, list: any) {
    this.seen = false;
  }

  //裁剪
  public cropImage(row: any,type:any){
    this.cropperType = type;
    if(type == '1'){
      this.updateImage(row);
      this.imgUrl = row.picurl;
    }else{
      this.imgUrl = row;
    }
    this.dialogStatusVisible = true;
  }

  //关闭裁剪弹框or得到子组件的值
   public vueCropper(e: any): void {
    if(e==false){
      this.dialogStatusVisible = false;
      return;
    }
    if(this.cropperType == '1'){
      this.form.picurl = e;
    }else{
      this.form.spicurl = e;
    }

    this.dialogStatusVisible = false;
  }

  //获取专栏
  public async applicationsList(): Promise<void> {
    const res = await this.wechatImageTextService.applicationsList();
    this.applications = res
    if (this.type == '1' && this.applications.length > 0) {//如果是添加的话，默认选中一个专栏
      // this.form.agentId = this.applications[0].channelAgentId
      this.$set(this.form, 'agentId', this.applications[0].channelAgentId)
      this.agentChanged(this.form.agentId)
    }
  }

  //获取指定分组
  public async sendMessageListAll(): Promise<void> {
    const res = await this.wechatSendMessageGroupService.sendMessageListAll(this.querySend);
    this.sendMessageList = res
  }

  //调起图片弹框
  public addPicture(types: any) {
    (this.$refs.childDialog as openDialog).upImage();
    this.imageType = types
  }
  //得到子组件传过来的值
  public getChild(val: any): void {
    this.$nextTick(() => {
      if (this.imageType == '1') {
        this.form.picurl = val.src
      }
      if (this.imageType == '2') {
        this.form.spicurl = val.src
        this.spicurlAlt = val.alt

      }
    })
  }

  //查询指定图文
  public async articleGet(): Promise<void> {
    const res = await this.wechatImageTextService.articleGet(this.id);
    this.imageList = res
    await this.formData()
    this.form = this.imageList[0]
    this.form.agentId = this.form.agentId.toString()
    //根据类型区分信息分享方式
    this.changeSendType(this.form.sendType)
  }

  //回显数据特殊处理
  public async formData(){
    for(let i=0;i<this.imageList.length;i++){
       let arr:any = this.imageList[i]
       //修改才pushid，选择图文不push
       if (this.type != '1') {
          this.articleIds.push(this.imageList[i].id);
       }
       this.agentChanged(arr.agentId)
        if(arr.groupIds!=undefined && arr.groupIds!=null && arr.groupIds!=''){
            this.form.groupIds = arr.groupIds= arr.groupIds.split(',').map(Number);
        }
        this.imageList[i].typeId = arr.typeId==0 ? '' : arr.typeId
        this.imageList[i].moments = arr.moments == null ? 0 : arr.moments;//是否分享到朋友圈
        //截取名字
        if(arr.spicurl != null){
            let path:any = arr.spicurl;
            if(path.indexOf("/")>0)//如果包含有"/"号 从最后一个"/"号+1的位置开始截取字符串
            {
              this.spicurlAlt= path.substring(path.lastIndexOf("/")+1,path.length);
            }
            else
            {
              this.spicurlAlt= path;
            }
        }
      }
    }


  public changeSendType(val: any): void {
    if (val == 0) {
      this.disabledTosend = true;
      this.disabledToall = false;
      this.form.tosend = 0;
      this.form.moments = 0;
    }
    if (val == 1) {
      this.disabledTosend = false;
      this.disabledToall = true;
      this.form.toall = 2;
    }
    if (val == 2) {
      this.disabledTosend = false;
      this.disabledToall = true;
      this.form.toall = 1;
    }
  }

  //开启图片保护为否的话单框提示
  public changeidentify(val:any){
    if(val == 1){ //表示关闭图片保护，则弹框提示
      const h = this.$createElement
      this.$confirm('', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          customClass:'messageBox',
          message:h('p', undefined, [
          h('span', undefined, '请您确认'),
          h('span', { style: 'color: red;font-weight:600;' }, '关闭'),
          h('span', undefined, '“图片保护”？您确认关闭“图片保护”的动作'),
          h('span', { style: 'color: red;font-weight:600;' }, '代表您承诺 '),
          h('span', { style: 'font-weight:600;' }, '本篇文章无任何个人信息或任何有关公司敏感和保密信息 （非公开信息）泄露的风险。'),
      ]),
        }).then(() => {

        }).catch(() => {
           this.form.identify = 0;
        });
    }
  }

  //获取图文类型
  public async getTypeQuery(): Promise<void> {
    const res = await this.wechatImageTextService.articleTypeQuery(this.queryParams);
    this.articleType = res.records
  }

  public async agentChanged(val: any) {
    sessionStorage.setItem('agentId',val)
    this.queryParams.agentId = val
    let checkId = this.applications.find(item => item.channelAgentId == val).id
    this.querySend.channelId = checkId  //根据id查询用户分组
    this.form.channelId = checkId
    await this.getTypeQuery();
    //查询指定分组
    await this.sendMessageListAll()
  }

  //保存前把数组转换为字符串
  public stringData() {
    for (let i = 0; i < this.imageList.length; i++) {
      let arr: any = this.imageList[i]
      this.imageList[i].htmlContent = arr.htmlContent;
      if (arr.toall == '1') {
        this.imageList[i].groupIds = '';
      }
      if (arr.groupIds != undefined && arr.groupIds != null && arr.groupIds.length > 0 && arr.groupIds != '') {
        this.imageList[i].groupIds = arr.groupIds.join(',')
      } else {
        this.imageList[i].groupIds = ''
      }
    }
  }
  //保存
  public submitForm(formName: any): void {
    let articleIds: any = this.articleIds.join(',');
    this.stringData()
    const addRef = (this.$refs.form as any)

    addRef.validate(async (valid: any) => {
      if (valid) {
        console.log('articleIds',this.imageList[0].typeId)
        if(this.imageList[0].typeId == '' ||this.imageList[0].typeId == null ||this.imageList[0].typeId == undefined){
          this.imageList[0].typeId =0;
        }
        if(!this.isAdd){
          this.isAdd = true;
          const res = await this.wechatImageTextService.articleAdd(articleIds, this.imageList);
        }
          this.$message({
            message: '保存图文成功',
            type: 'success'
          });
          this.$router.push("/wechatImageText");
      } else {
        console.log('error submit!!');
        return false;
      }
    });
  }
  //返回
  public async handleGoBack(): Promise<void> {
    this.$router.go(-1)
  }
  public async handleClick(tab: any, event: any): Promise<void> {
    console.log(tab, event);
  }
   //复制
  public copyUrl() {
    var oInput = document.createElement('input');
    oInput.value = this.form.historyUrl;
    document.body.appendChild(oInput);
    oInput.select();
    document.execCommand("Copy");
    this.$message({
      type: 'success',
      message: '复制成功'
    });
    oInput.remove();
  }
  //清除
  public clear() {
    this.form.spicurl = null;
    this.spicurlAlt = '';
  }
  public async destroyed(): Promise<void> {
    sessionStorage.removeItem("agentId");
  }
  /**
   * -END- CALL SERVICE
   */
}
